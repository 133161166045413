// @flow
import axios from 'src/settings/axios'
import React, { useState, useEffect } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import Navigation from 'src/components/organisms/Navigation'
import Typography from '@material-ui/core/Typography'
import FeatureGroup from 'src/components/molecules/FeatureGroup'
import PeopleAltIcon from '@material-ui/icons/PeopleAlt'
import AssignmentIcon from '@material-ui/icons/Assignment'
import LoyaltyIcon from '@material-ui/icons/Loyalty'
import { ReactComponent as DiscountIcon } from 'src/images/discount.svg'
import Button from 'src/components/atoms/buttons/Button'
import TrendingFlatIcon from '@material-ui/icons/TrendingFlat'
import PaymentForm from 'src/components/organisms/forms/PaymentForm'
import { Elements, StripeProvider } from 'react-stripe-elements'
import env from 'src/settings/env'
import type { State } from 'src/utils/types'
import type { ProfileState } from 'src/state/reducers/profile'
import type { PaymentState } from 'src/state/reducers/payments'
import { useAuth } from 'src/hooks/useAuth'
import { useStyles } from './index.styles'
import { CircularProgress } from '@material-ui/core'

const selectState: State => PaymentState & ProfileState = state => ({
  ...state.payments,
  ...state.profile
})

const PRO_ACCESS_PERKS = [
  'As a part of our community, publishers reach out to songwriters first. Sooner immediately notifies you when a professional follows you or wants to connect!'
]

const PUBLISHING_FIELDS_PERKS = [
  'Our events and programs give you direct access to the publishers and professionals who can make a difference.'
]

const REFERRAL_FIELDS_PERKS = [
  'Increase your discoverability. Referred writers are listed with top writers in the search filters and receive a “referred” badge on their profile.'
]

export default () => {
  const styles = useStyles()
  const [activeTab, setActiveTab] = useState(0)
  const [monthlyPrice, setMonthlyPrice] = useState("13")
  const [yearlyPrice, setYearlyPrice] = useState("10")
  const [loadingPrices, setLoadingPrices] = useState(true)
  const [isPaymentModalOpen, setIsPaymentModalOpen] = useState(false)
  const state: PaymentState & ProfileState = useSelector(
    selectState,
    shallowEqual
  )

  useEffect(() => {
    try {
      axios.get('/payments/prices').then(({ data }) => {
        setMonthlyPrice((data.monthly.unit_amount / 100).toFixed(2).replace('.00', ''))
        setYearlyPrice((data.yearly.unit_amount / 1200).toFixed(2).replace('.00', ''))
        setLoadingPrices(false)
      })
    } catch (e) {
      console.log('e=>', e)
      setLoadingPrices(false)
    }
  }, [])

  const { user } = useAuth()

  const onClick = () => {
    setIsPaymentModalOpen(true)
  }

  return (
    <Navigation pageTitle="Billing">
      <div className={styles.wrapper}>
        <Typography variant="h2" component="h1" className={styles.header}>
          <span className={styles.emphasizedText}>Enter Billing Information</span>
        </Typography>
        {/*loadingPrices && <CircularProgress></CircularProgress>}
        {!loadingPrices &&
        <Typography variant="h6" component="h2" className={styles.price}>
          ${yearlyPrice} / month billed annually
          <Typography
            variant="h6"
            component="span"
            className={styles.monthlyPrice}
          >
            (or ${monthlyPrice} / month)
          </Typography> plus sales tax where applicable
        </Typography>
        */}
        {/* <div className={styles.tabsWrapper}>
          <Tabs variant="fullWidth" className={styles.tabs}>
            <Tab label="CAREER" onClick={() => setActiveTab(0)} />
            <Tab label="PREMIUM" onClick={() => setActiveTab(1)} />
          </Tabs>
        </div> */}
        <div className={styles.featureList}>
          {activeTab === 0 && (
            <>
              <FeatureGroup
                Icon={LoyaltyIcon}
                header="Become a member!"
                perks={PUBLISHING_FIELDS_PERKS}
              />
              <FeatureGroup
                Icon={PeopleAltIcon}
                header="Industry Access"
                perks={PRO_ACCESS_PERKS}
              />
              <FeatureGroup
                Icon={AssignmentIcon}
                header="Request a Referral"
                perks={REFERRAL_FIELDS_PERKS}
              />
            </>
          )}
          {/* {activeTab === 1 && (
            <>
              <FeatureGroup
                Icon={MailIcon}
                header="InMail Messages"
                desc="You get 4 InMail messages you can use to reach out to any songwriter not currently in your network."
              />
              <FeatureGroup
                Icon={RocketIcon}
                header="Boost Posts"
                desc="Each month you can send out a powerful boost post that will show up on the dashboard of all industry professionals."
              />
              <FeatureGroup
                Icon={TrendingUpIcon}
                header="Search Trends"
                desc="See detailed search trends of industry professionals and understand the demands of your industry. (COMING SOON)"
              />
            </>
          )} */}
        </div>
        <div className={styles.upgradeButtonWrapper}>
          <Button
            variant="contained"
            color="primary"
            disabled={
              !user ||
              user.accountLevel === 'Career' ||
              (activeTab === 1 && !user?.isReferred)
            }
            onClick={onClick}
          >
            <Typography
              color="inherit"
              className={styles.upgradeButtonText}
              variant="h6"
            >
              {activeTab === 0
                ? user && user.accountLevel === 'Career'
                  ? 'CURRENTLY ON CAREER PLAN'
                  : 'ENTER BILLING INFORMATION'
                : user && user.isReferred
                ? 'UPGRADE TO PREMIUM'
                : 'REFERRAL REQUIRED'}
            </Typography>
            <TrendingFlatIcon />
          </Button>
        </div>
      </div>
      <StripeProvider apiKey={env.STRIPE_API_KEY}>
        <Elements>
          <PaymentForm
            monthlyPrice={monthlyPrice}
            yearlyPrice={yearlyPrice}
            email={user && user.email}
            plan="Career"
            isOpen={isPaymentModalOpen}
            onClose={() => setIsPaymentModalOpen(false)}
            error={state.error}
            success={state.paymentSucceeded}
            isSubmitting={state.isSubmitting}
          />
        </Elements>
      </StripeProvider>
    </Navigation>
  )
}
