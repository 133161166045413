// @flow
import { push } from 'react-router-redux'
import type { ThunkAction } from 'src/utils/types'
import {
  submitPayment,
  cancelSubscription,
  getPaymentRecord,
  getCheckoutSession
} from 'src/services/payments'
import {
  SUBMIT_PAYMENT_REQUEST,
  SUBMIT_PAYMENT_SUCCESS,
  SUBMIT_PAYMENT_ERROR,
  CANCEL_SUBSCRIPTION_REQUEST,
  CANCEL_SUBSCRIPTION_SUCCESS,
  CANCEL_SUBSCRIPTION_ERROR,
  GET_PAYMENT_RECORD_REQUEST,
  GET_PAYMENT_RECORD_SUCCESS,
  GET_PAYMENT_RECORD_ERROR,
  GET_CHECKOUT_SESSION_REQUEST,
  GET_CHECKOUT_SESSION_SUCCESS,
  GET_CHECKOUT_SESSION_ERROR
} from 'src/state/reducers/payments'
import { getServiceErrors } from 'src/utils/api/apiErrors'
import { getUserAction } from 'src/state/actions/profile'
import type {
  SubmitPaymentService,
  CancelSubscriptionService,
  GetPaymentRecordService,
  GetCheckoutSessionService
} from 'src/services/payments'
import type { User } from 'src/utils/auth/users'

export type SubmitPaymentParams = {
  email: string,
  cardElement: any,
  stripeObject: any,
  plan: 'Career' | 'Premium',
  planPeriod: 'Monthly' | 'Yearly',
  coupon?: string,
  user: User,
  updateUser: User => void,
  service?: SubmitPaymentService
}

export type SubmitPaymentAction = SubmitPaymentParams => ThunkAction

export const submitPaymentAction: SubmitPaymentAction = ({
  email,
  stripeObject,
  cardElement,
  planPeriod,
  plan,
  user,
  updateUser,
  coupon,
  service = submitPayment
}) => async dispatch => {
  try {
    dispatch({ type: SUBMIT_PAYMENT_REQUEST })
    const { paymentMethod } = await stripeObject.createPaymentMethod({
      type: 'card',
      card: cardElement,
      billing_details: {
        email
      }
    })
    const { data } = await service({
      plan,
      paymentMethod: paymentMethod.id,
      planPeriod,
      coupon
    })
    if (data.success) {
      const updatedUser = { ...user, accountLevel: plan }
      updateUser(updatedUser)
      dispatch({ type: SUBMIT_PAYMENT_SUCCESS })
    } else {
      dispatch({ type: SUBMIT_PAYMENT_ERROR, payload: data.action })
    }
  } catch (err) {
    const errors = getServiceErrors(err)
    dispatch({
      type: SUBMIT_PAYMENT_ERROR,
      payload: 'Payment failed. Please try again with a different card.'
    })
    if (errors.unauthorized) {
      dispatch(push('/login'))
    }
    return Promise.reject(getServiceErrors(err))
  }
}

export type CancelSubscriptionParams = {
  user?: User,
  service?: CancelSubscriptionService
}

export type CancelSubscriptionAction = CancelSubscriptionParams => ThunkAction

export const cancelSubscriptionAction: CancelSubscriptionAction = ({
  user,
  service = cancelSubscription
}) => async dispatch => {
  try {
    dispatch({ type: CANCEL_SUBSCRIPTION_REQUEST })
    await service()
    dispatch({ type: CANCEL_SUBSCRIPTION_SUCCESS })
    if (user) {
      user.accountLevel = 'Free'
      dispatch(getUserAction({ id: user._id }))
    }
  } catch (err) {
    const errors = getServiceErrors(err)
    dispatch({
      type: CANCEL_SUBSCRIPTION_ERROR,
      payload:
        'There was an error cancelling your subscription. Please contact support.'
    })
    if (errors.unauthorized) {
      dispatch(push('/login'))
    }
    return Promise.reject(getServiceErrors(err))
  }
}

export type GetPaymentRecordParams = {
  user: User,
  service?: GetPaymentRecordService
}

export type GetPaymentRecordAction = GetPaymentRecordParams => ThunkAction

export const getPaymentRecordAction: GetPaymentRecordAction = ({
  user,
  service = getPaymentRecord
}) => async dispatch => {
  try {
    if (!user) return dispatch(push('/login'))
    dispatch({ type: GET_PAYMENT_RECORD_REQUEST })
    const { data } = await service(user._id)
    dispatch({ type: GET_PAYMENT_RECORD_SUCCESS, payload: data })
  } catch (err) {
    const errors = getServiceErrors(err)
    dispatch({
      type: GET_PAYMENT_RECORD_ERROR,
      payload: 'There was an error fetching payment record'
    })
    if (errors.unauthorized) {
      dispatch(push('/login'))
    }
    return Promise.reject(getServiceErrors(err))
  }
}

export type GetCheckoutSessionParams = {
  user: User,
  service?: GetCheckoutSessionService
}

export type GetCheckoutSessionAction = GetCheckoutSessionParams => ThunkAction

export const getCheckoutSessionAction: GetCheckoutSessionAction = ({
  user,
  service = getCheckoutSession
}) => async dispatch => {
  try {
    if (!user) return dispatch(push('/login'))
    dispatch({ type: GET_CHECKOUT_SESSION_REQUEST })
    const { data } = await service()
    dispatch({ type: GET_CHECKOUT_SESSION_SUCCESS, payload: data })
    return data
  } catch (err) {
    const errors = getServiceErrors(err)
    dispatch({
      type: GET_CHECKOUT_SESSION_ERROR,
      payload: 'There was an error fetching a checkout session'
    })
    if (errors.unauthorized) {
      dispatch(push('/login'))
    }
    return Promise.reject(getServiceErrors(err))
  }
}
